// Contact.js
import React from 'react';
import { FaEnvelope, FaMapMarkerAlt, FaPhone } from 'react-icons/fa';
import classes from './Contact1.module.css'; // Assuming you're using CSS Modules

const Contact = () => {
    return (
        <div className={classes.container}>
            <div className={classes.grid}>
                {/* Contact Card 1: Email Us */}
                <div className={classes.card}>
                    <FaEnvelope size={32} className={classes.ico} />
                    <br/>
                    <p className={classes.ico2}>Email Us</p>
                    <p>
                    <div>
                        <p className={classes.ico1}>For service requests or complaints write to</p>
                        <p className={classes.ico1}>nmt@nmtpackaging.com</p>
                    </div>
                    </p>
                </div>
                
                {/* Contact Card 2: Factory Address */}
                <div className={classes.card}>
                    <FaMapMarkerAlt size={32} className={classes.ico} />
                    <br/>
                    <p className={classes.ico2}>Office</p>
                   <p>
                    <div>
                        <p className={classes.ico1}>215,Blue Rose Industiral Estate, Wastern Express Highway, Borivali East, Next To Metro Cash And Carry, 400066</p>
                       
                    </div>
                    </p>
                </div>
                
                {/* Contact Card 3: Office Address */}
                <div className={classes.card}>
                    <FaMapMarkerAlt size={32} className={classes.ico} />
                    <br/>
                    <p className={classes.ico2}>Factory</p>
                   <p> <div>
                        <p className={classes.ico1}>NMT Packaging Pvt Ltd, Gut No-293, Dakivali Village, Wada Taluk, Palghar, 421312</p>
                    </div></p>
                </div>
                
                {/* Contact Card 4: Call Us */}
                <div className={classes.card}>
                    <FaPhone size={32} className={classes.ico} />
                    <p className={classes.ico2}>Call Us</p>
                    <div >
                        <p className={classes.ico1}>
                            Name 1234567890
                            <br/>
                            Name 1234567890</p>
                       
                  
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contact;
