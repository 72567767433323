import React from 'react'
import classes from "./box.module.css"
import { Link } from 'react-router-dom';
import Fade from 'react-reveal/Fade'

import zz2 from './../../Assest/b6.jpeg';
import zz12 from './../../Assest/c.png';
import zz25 from './../../Assest/h.png';


const box = () => {
  return (
    <div className={classes.steps}>

        <h2 className={classes.ih3h}>Why Choose NMT Packaging Pvt Ltd</h2>

<br/>

    


<div className={classes.steps1}> 
<Fade left >
<div className={classes.dbn}> 
<ul className={classes.ul}>

<li className={classes.li}><span className={classes.bed}>Quality Assurance: </span>Every packaging solution is crafted with precision and attention to detail to ensure the highest standards of quality.</li>
<li className={classes.li}> <span className={classes.bed}>Innovative Design: </span>We use advanced technology and design principles to create packaging that is both functional and visually appealing.</li>
<li className={classes.li}> <span className={classes.bed}>Sustainability: </span>Our commitment to sustainable practices means that we offer eco-friendly packaging solutions that reduce environmental impact.</li>
<li className={classes.li}> <span className={classes.bed}>Customer-Centric Approach: </span>We work closely with our clients to understand their needs and deliver packaging solutions that exceed their expectations.</li>


</ul>
</div>
</Fade>
<Fade right>
<div className={classes.dbn}> 
<img src={zz2} className={classes.ekl}></img>
</div>

</Fade>


</div>

<br/> <br/>
<br/>




    </div>
  )
}

export default box


