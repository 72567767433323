import {useEffect} from 'react'
import { Outlet } from "react-router-dom";
import Navi from './../Component/Nav/Nav';
import Header from './../Component/Nav/Header';
import Nav from './../Component/Nav/Navi';
import  Footer  from "../Component/Footer/Footer";
import  Footer1  from "../Component/Footer/footer1";
import Wpp from "../Component/Wpp/Wpp"; 

const RootLayout = () => {

  useEffect(() => {
  window.scrollTo(0, 0);

  // Home Page Meta Tags
  document.title = 'NMT Packaging Pvt Ltd | Leading Packaging Solutions in Mumbai, Pune & Maharashtra';

  const homeMetaDescription = document.createElement('meta');
  homeMetaDescription.name = 'description';
  homeMetaDescription.content = 'Welcome to NMT Packaging Pvt Ltd! Discover top-notch corrugated packaging solutions in Mumbai, Pune, and Maharashtra. We offer innovative and sustainable packaging designed for various industries. Explore our advanced technology and high-quality products tailored to meet your packaging needs.';
  document.head.appendChild(homeMetaDescription);

  const homeMetaKeywords = document.createElement('meta');
  homeMetaKeywords.name = 'keywords';
  homeMetaKeywords.content = 'Packaging Solutions Mumbai, Corrugated Packaging Maharashtra, Packaging Industry Pune, Custom Packaging Mumbai, High-Quality Packaging Maharashtra, Sustainable Packaging Pune, Packaging Services Mumbai, Innovative Packaging Solutions Maharashtra, NMT Packaging Pvt Ltd, Industrial Packaging Mumbai, E-Commerce Packaging Pune, FMCG Packaging Maharashtra';
  document.head.appendChild(homeMetaKeywords);

  const homeCanonicalLink = document.createElement('link');
  homeCanonicalLink.rel = 'canonical';
  homeCanonicalLink.href = 'https://nmt.eshikatech.com';
  document.head.appendChild(homeCanonicalLink);

  const homeOgTitle = document.createElement('meta');
  homeOgTitle.property = 'og:title';
  homeOgTitle.content = 'NMT Packaging Pvt Ltd | Leading Packaging Solutions in Mumbai, Pune & Maharashtra';
  document.head.appendChild(homeOgTitle);

  const homeOgDescription = document.createElement('meta');
  homeOgDescription.property = 'og:description';
  homeOgDescription.content = 'Welcome to NMT Packaging Pvt Ltd! Discover top-notch corrugated packaging solutions in Mumbai, Pune, and Maharashtra.';
  document.head.appendChild(homeOgDescription);

  const homeOgUrl = document.createElement('meta');
  homeOgUrl.property = 'og:url';
  homeOgUrl.content = 'https://nmt.eshikatech.com';
  document.head.appendChild(homeOgUrl);

  const homeOgSiteName = document.createElement('meta');
  homeOgSiteName.property = 'og:site_name';
  homeOgSiteName.content = 'NMT Packaging Pvt Ltd';
  document.head.appendChild(homeOgSiteName);

  const homeOgImage = document.createElement('meta');
  homeOgImage.property = 'og:image';
  homeOgImage.content = 'https://nmt.eshikatech.com/nmt4.png';
  document.head.appendChild(homeOgImage);

  const homeOgImageSecure = document.createElement('meta');
  homeOgImageSecure.property = 'og:image:secure_url';
  homeOgImageSecure.content = 'https://nmt.eshikatech.com/nmt4.png';
  document.head.appendChild(homeOgImageSecure);

  const homeOgImageWidth = document.createElement('meta');
  homeOgImageWidth.property = 'og:image:width';
  homeOgImageWidth.content = '300';
  document.head.appendChild(homeOgImageWidth);

  const homeOgImageHeight = document.createElement('meta');
  homeOgImageHeight.property = 'og:image:height';
  homeOgImageHeight.content = '200';
  document.head.appendChild(homeOgImageHeight);

  // Structured Data for Home Page
  const homeStructuredData = document.createElement('script');
  homeStructuredData.type = 'application/ld+json';
  homeStructuredData.textContent = JSON.stringify({
    "@context": "https://schema.org",
    "@type": "Organization",
    "url": "https://nmt.eshikatech.com",
    "name": "NMT Packaging Pvt Ltd",
    "logo": {
      "@type": "ImageObject",
      "url": "https://nmt.eshikatech.com/nmt4.png"
    }
  });
  document.head.appendChild(homeStructuredData);

  return () => {
    document.head.removeChild(homeMetaDescription);
    document.head.removeChild(homeMetaKeywords);
    document.head.removeChild(homeCanonicalLink);
    document.head.removeChild(homeOgTitle);
    document.head.removeChild(homeOgDescription);
    document.head.removeChild(homeOgUrl);
    document.head.removeChild(homeOgSiteName);
    document.head.removeChild(homeOgImage);
    document.head.removeChild(homeOgImageSecure);
    document.head.removeChild(homeOgImageWidth);
    document.head.removeChild(homeOgImageHeight);
    document.head.removeChild(homeStructuredData);
  };

}, []);




    return <div>
 
      <Header></Header>
      <Nav></Nav>
        <Outlet></Outlet>
        <Wpp></Wpp>
         {/* <Footer></Footer> */}
           <Footer1></Footer1>
         
    </div>
};

export default RootLayout;