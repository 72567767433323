import React from 'react';
import classes from "./Big.module.css";
import W from "./../../Assest/tran.mp4";

const Big = () => {
    return (
        <div className={classes.simulation}>
            <br /> <br />
            <video autoPlay muted loop className={classes.video}>
                <source src={W} type="video/mp4" />
    
        </video>

        <div className={classes.tt}>
            
            <div className={classes.text}>
            
                <div className={classes.Bp}>
                <p className={classes.Bp1}>
                   <h1 className={classes.bh1}>
                    Welcome To <br />
                   NMT Packaging Pvt Ltd</h1>
                </p>
          
            </div>
            </div>


  
        </div>

        </div>

    );
}

export default Big;
