import React from 'react'
import classes from "./Benifits.module.css"
import img1 from "./../../Assest/h1.jpg"
import img2 from "./../../Assest/h2.png"
import img3 from "./../../Assest/h3.png"
import img4 from "./../../Assest/h4.png"
import img5 from "./../../Assest/h5.png"
import img6 from "./../../Assest/h6.png"
import img7 from "./../../Assest/h7.png"
import img8 from "./../../Assest/h8.png"
import Fade from "react-reveal/Fade"

const Benifits = () => {
  return (
    <div>
<br/><br/>
    <h2 className={classes.ih3h}>Industries We Serve</h2>
<div className={classes.pro1}>

<Fade left>
<div className={classes.pro}>
  <img src={img1} className={classes.img} /> 
  <h4 className={classes.h4}> E-Commerce </h4>
</div></Fade>
<Fade left>
<div className={classes.pro}>
  <img src={img2} className={classes.img} /> 
  <h4 className={classes.h4}> Fast Moving Consuming Goods</h4>
</div></Fade>
<Fade right>
<div className={classes.pro}>
  <img src={img3} className={classes.img} /> 
  <h4 className={classes.h4}> Process Foods and Beverages</h4>
</div></Fade>
<Fade right>
<div className={classes.pro}>
  <img src={img4} className={classes.img} /> 
  <h4 className={classes.h4}> Consumer Durables </h4>
</div></Fade>




</div>

<div className={classes.pro1}>

<Fade left>
<div className={classes.pro}>
  <img src={img5} className={classes.img} /> 
  <h4 className={classes.h4}> Merchandies and Garments</h4>
</div></Fade>
<Fade left>
<div className={classes.pro}>
  <img src={img6} className={classes.img} /> 
  <h4 className={classes.h4}> Fire Fighting Equipment </h4>
</div></Fade>
<Fade right>
<div className={classes.pro}>
  <img src={img7} className={classes.img} /> 
  <h4 className={classes.h4}> Pestisides and Equipment</h4>
</div></Fade>
<Fade right>
<div className={classes.pro}>
  <img src={img8} className={classes.img} /> 
  <h4 className={classes.h4}> Pharma Industries </h4>
</div></Fade>




</div>



    </div>
  )
}

export default Benifits