import React from 'react';
import classes from './Machinary1.module.css'; // Import your CSS file
import Fade from 'react-reveal/Fade';
const NMTPackaging = () => {
    return (
        <div className={classes.container}>

        <Fade left>    <p className={classes.intro}>
                Some of our recent plants feature the best infrastructure in India to date. We have not only adopted advanced design concepts and CE-compliant capabilities in our machinery but have also developed a highly professional and loyal team to operate these facilities. This skilled workforce, combined with our state-of-the-art infrastructure, positions NMT Packaging Pvt Ltd for continued growth and success in the future.
            </p>
</Fade>

<Fade right>
            <section className={classes.section}>
                <h2 className={classes.sectionTitle}>Our Machinery & Innovations</h2>
                <ul className={classes.list}>
                    <li>State-of-the-art double wall corrugators equipped with advanced technology, robust design, precision, and automation for sustainable and efficient board output.</li>
                    <li>Innovative Flexo Folder Gluers that operate without a crush feeder, providing ergonomic designs with high productivity, accuracy, and consistency.</li>
                    <li>Flexo printer slotters featuring rotary die-cutters and vibrator stackers.</li>
                    <li>A state-of-the-art sample maker equipped with Artios CAD software for generating samples on short notice.</li>
                    <li>Crash Lock Bottom Gluers specializing in producing boxes for the frozen desserts, beverages, and food industries.</li>
                </ul>
            </section>
</Fade>
       <Fade left>     <section className={classes.section}>
                <h2 className={classes.sectionTitle}>Additional Equipment</h2>
                <ul className={classes.list}>
                    <li>Automatic pre-feeders for flexo folder gluers, stitcher gluers, and offline folder gluers.</li>
                    <li>Automatic high-speed strapping machines.</li>
                    <li>Inline slitting and scoring machines with cut-off units equipped with computerized numerical control for cutting accuracy.</li>
                    <li>Platen and flat bed die-cut machines.</li>
                    <li>Waste baling machines, shredders, and a waste disposal system with cyclone technology.</li>
                </ul>
            </section>
</Fade>
   <Fade right>
            <section className={classes.section}>
                <h2 className={classes.sectionTitle}>Energy and Handling Solutions</h2>
                <ul className={classes.list}>
                    <li>Integrated furnace-type boilers.</li>
                    <li>Diesel generating sets.</li>
                    <li>Forklifts featuring forks and paper roll clamp systems.</li>
                    <li>Conveyor board handling systems for streamlined operations.</li>
                </ul>
            </section>
            </Fade>
        </div>
    );
};

export default NMTPackaging;
