import React from 'react';
import styles from './P3.module.css';
import P from "./../../Assest/p3.jpg"
import Fade from 'react-reveal/Fade';

const MonoCartons = () => {
  return (
    <div className={styles.bgPurple}>
      <div className={`${styles.container} ${styles.flex}`}> <Fade left>
        <div className={styles.imageContainer}>
          <img
            alt="Two perfume boxes, one purple labeled 'MIDNIGHT' and one green labeled 'COLOGNE'"
            className={styles.rotatedImage}
            height="300"
            // src="https://storage.googleapis.com/a1aa/image/GVvsK9nOGaL1MNOyOXQLe4IsQTvcrwuqv2IWRQhs1fmpGxoTA.jpg"
            src={P}
            width="300"
          />
        </div></Fade>
        <div className={styles.textContainer}> <Fade right>
          <h1 className={styles.heading}>Die-cut Boxes</h1>
          <p className={styles.paragraph}>
          Explore NMT Packaging Pvt Ltd's die-cut boxes, expertly crafted for precision and versatility. These custom-shaped boxes offer a perfect fit for your products, enhancing presentation and protection. Made from high-quality materials, they ensure durability during shipping and storage. Ideal for a variety of applications, they can be tailored to meet your specific branding needs. Trust NMT Packaging for innovative die-cut solutions that make your packaging stand out. </p>
          <div className={styles.buttonContainer}>
           <button className={styles.startProjectButton}>Learn More</button>
          
          </div></Fade>
        </div>
      </div>
    </div>
  );
};

export default MonoCartons;
