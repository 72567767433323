import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import ReactGA from "react-ga4";

import Home from "./Pages/Home";
import About from "./Pages/About";
import Contact from "./Pages/Contact";
import Service from "./Pages/Machinary";
import Products from "./Pages/Products";
import Clientele from "./Pages/Clientele";
import RootLayout from "./Pages/Rootlayoout";

function App() {
  // const trackingId = "G-WBN7QLGH4G";
  // ReactGA.initialize(trackingId);

  // useEffect(() => {
  //   const handlePageView = () => {
  //     ReactGA.pageview(window.location.pathname + window.location.search);
  //   };

  //   // Track initial page load
  //   handlePageView();

  //   // Track page views on popstate events (back/forward navigation)
  //   window.addEventListener('popstate', handlePageView);

  //   return () => {
  //     window.removeEventListener('popstate', handlePageView);
  //   };
  // }, []);


  ReactGA.initialize("G-WBN7QLGH4G");
ReactGA.send({
  hitType:"pageview",
  page:window.location.pathname,
})

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<RootLayout />}>
            <Route index element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/machinery" element={<Service />} />
            <Route path="/products" element={< Products  />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/clientele" element={<Clientele />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
