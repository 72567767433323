import React from 'react';
import Fade from 'react-reveal/Fade'; // Import the Fade component
import classes from './Clientele.module.css'; // Import your CSS file

const MarqueeClientele = () => {
    const industries = [
        { name: "FMCG", img: "https://cdn-icons-png.flaticon.com/128/756/756940.png" },
        { name: "Processed Foods & Beverages", img: "https://cdn-icons-png.flaticon.com/128/3107/3107249.png" },
        { name: "E-Commerce", img: "https://cdn-icons-png.flaticon.com/128/7466/7466065.png" },
        { name: "White Goods Appliances & Consumer Durables", img: "https://cdn-icons-png.flaticon.com/128/2979/2979677.png" },
        { name: "Home Merchandise & Garments", img: "https://cdn-icons-png.flaticon.com/128/10340/10340243.png" },
        { name: "Pesticides & Crop Sciences", img: "https://cdn-icons-png.flaticon.com/128/809/809131.png" },
        { name: "Paints & Automotive Sector", img: "https://cdn-icons-png.flaticon.com/128/7816/7816132.png" },
    ];

    return (
        <div className={classes.container}>
            <div className="mb-8">
                <h1 className={classes.header}>Our Clientele</h1>
                <p className={classes.paragraph}>
                    Our clients are who's who in India and world over. We cover right from Fast Moving Goods category to White goods (Electronics, House hold Appliances) to Foods & Beverages. We cover most of the multinationals operating in India. These clients have been nurtured by us over three decades by building and maintaining very close rapport at all levels.
                </p>
            </div>
            <div>
                <h2 className={classes.subheader}>Industries We Cover</h2>
                <div className={classes.grid}>
                    {industries.map((industry, index) => (
                        <Fade key={index} left={index % 2 === 0} right={index % 2 !== 0}>
                            <div className={classes.item}>
                                <img src={industry.img} alt={industry.name} className={classes.image} />
                                <span className={classes.text}>{industry.name}</span>
                            </div>
                        </Fade>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default MarqueeClientele;
