import React from "react"
import classes from "./Rec.module.css"
import a1 from "./../../Assest/r1.png";
import a2 from "./../../Assest/g2.avif";
import Fade from 'react-reveal/Fade';

const Rec = () => {
    return (
        
    <>


<div className={classes.a1}>
    <div className={classes.a11}>
    <Fade right>
<h3 className={classes.ih4}>Our Recycling Practice</h3>
<p className={classes.ih41}>At NMT Packaging Pvt Ltd, sustainability is at the core of our operations. Our recycling initiatives include:: Reducing waste by recycling used corrugated materials into new packaging products.Incorporating recycled paper into our manufacturing process to minimize the use of virgin materials.
We are dedicated to minimizing our environmental impact through continuous improvement in recycling practices and technology upgrades. Our goal is to provide exceptional packaging solutions while contributing to a sustainable future.

</p>
</Fade>
</div>
 <Fade left>
<div  className={classes.a12}>
        <img src={a1} className={classes.img} />
</div>
</Fade>


</div>



        </>
    )
}

export default Rec;