import React, { useEffect } from 'react';
import Big from "./../Component/Home/Big";
import Box from "./../Component/Home/box";
import Cert from "./../Component/Home/Cert";
import Benifits from "./../Component/Home/Benifits";
import Testimonials from "./../Component/Home/Testimonials";
import Elegent from "./../Component/Home/eleg";
import Clients from "./../Component/Home/ourclients";
import Counter from "./../Component/About/Counter.js"
import Rec from "./../Component/Home/Rec.js"
import Awrd from "./../Component/Home/Award"
import P1 from "./../Component/Home/P1"
import P2 from "./../Component/Home/P2"
import P3 from "./../Component/Home/P3"
import P4 from "./../Component/Home/P4"
import P5 from "./../Component/Home/P5"
import H1 from "./../Component/Home/H1"
import Clie from "./../Component/Home/clie"
import Sf from "./../Component/Home/sfy"


const Home = () => {

  useEffect(() => {
    window.scrollTo(0, 0);

    // Home Page Meta Tags
    document.title = 'NMT Packaging Pvt Ltd | Leading Packaging Solutions in Mumbai, Pune & Maharashtra';

    const homeMetaDescription = document.createElement('meta');
    homeMetaDescription.name = 'description';
    homeMetaDescription.content = 'Welcome to NMT Packaging Pvt Ltd! Discover top-notch corrugated packaging solutions in Mumbai, Pune, and Maharashtra. We offer innovative and sustainable packaging designed for various industries. Explore our advanced technology and high-quality products tailored to meet your packaging needs.';
    document.head.appendChild(homeMetaDescription);

    const homeMetaKeywords = document.createElement('meta');
    homeMetaKeywords.name = 'keywords';
    homeMetaKeywords.content = 'Packaging Solutions Mumbai, Corrugated Packaging Maharashtra, Packaging Industry Pune, Custom Packaging Mumbai, High-Quality Packaging Maharashtra, Sustainable Packaging Pune, Packaging Services Mumbai, Innovative Packaging Solutions Maharashtra, NMT Packaging Pvt Ltd, Industrial Packaging Mumbai, E-Commerce Packaging Pune, FMCG Packaging Maharashtra';
    document.head.appendChild(homeMetaKeywords);

    const homeCanonicalLink = document.createElement('link');
    homeCanonicalLink.rel = 'canonical';
    homeCanonicalLink.href = 'https://nmt.eshikatech.com';
    document.head.appendChild(homeCanonicalLink);

    const homeOgTitle = document.createElement('meta');
    homeOgTitle.property = 'og:title';
    homeOgTitle.content = 'NMT Packaging Pvt Ltd | Leading Packaging Solutions in Mumbai, Pune & Maharashtra';
    document.head.appendChild(homeOgTitle);

    const homeOgDescription = document.createElement('meta');
    homeOgDescription.property = 'og:description';
    homeOgDescription.content = 'Welcome to NMT Packaging Pvt Ltd! Discover top-notch corrugated packaging solutions in Mumbai, Pune, and Maharashtra.';
    document.head.appendChild(homeOgDescription);

    const homeOgUrl = document.createElement('meta');
    homeOgUrl.property = 'og:url';
    homeOgUrl.content = 'https://nmt.eshikatech.com';
    document.head.appendChild(homeOgUrl);

    const homeOgSiteName = document.createElement('meta');
    homeOgSiteName.property = 'og:site_name';
    homeOgSiteName.content = 'NMT Packaging Pvt Ltd';
    document.head.appendChild(homeOgSiteName);

    const homeOgImage = document.createElement('meta');
    homeOgImage.property = 'og:image';
    homeOgImage.content = 'https://nmt.eshikatech.com/nmt4.png';
    document.head.appendChild(homeOgImage);

    const homeOgImageSecure = document.createElement('meta');
    homeOgImageSecure.property = 'og:image:secure_url';
    homeOgImageSecure.content = 'https://nmt.eshikatech.com/nmt4.png';
    document.head.appendChild(homeOgImageSecure);

    const homeOgImageWidth = document.createElement('meta');
    homeOgImageWidth.property = 'og:image:width';
    homeOgImageWidth.content = '300';
    document.head.appendChild(homeOgImageWidth);

    const homeOgImageHeight = document.createElement('meta');
    homeOgImageHeight.property = 'og:image:height';
    homeOgImageHeight.content = '200';
    document.head.appendChild(homeOgImageHeight);

    // Structured Data for Home Page
    const homeStructuredData = document.createElement('script');
    homeStructuredData.type = 'application/ld+json';
    homeStructuredData.textContent = JSON.stringify({
      "@context": "https://schema.org",
      "@type": "Organization",
      "url": "https://nmt.eshikatech.com",
      "name": "NMT Packaging Pvt Ltd",
      "logo": {
        "@type": "ImageObject",
        "url": "https://nmt.eshikatech.com/nmt4.png"
      }
    });
    document.head.appendChild(homeStructuredData);

    return () => {
      document.head.removeChild(homeMetaDescription);
      document.head.removeChild(homeMetaKeywords);
      document.head.removeChild(homeCanonicalLink);
      document.head.removeChild(homeOgTitle);
      document.head.removeChild(homeOgDescription);
      document.head.removeChild(homeOgUrl);
      document.head.removeChild(homeOgSiteName);
      document.head.removeChild(homeOgImage);
      document.head.removeChild(homeOgImageSecure);
      document.head.removeChild(homeOgImageWidth);
      document.head.removeChild(homeOgImageHeight);
      document.head.removeChild(homeStructuredData);
    };

  }, []);


  return (
    <div>
      {/* <Big></Big> */}
      <H1 />
      <Elegent />
      <Awrd />
      <Cert />
   
      <Box />
      <Counter />
      <Sf />

      <Benifits />
<P1/><P2/><P3/><P4/><P5/>
{/* <Clie /> */}
    {/* <Clients /> */}
      <Rec />

      {/* <Testimonials /> */}
    </div>
  );
}

export default Home;
