import React,{useEffect} from 'react'
import Cl from "./../Component/Clientele/Clientele"

import Banner from "./../Component/Clientele/Clientbanner"





const About = () => {
useEffect(() => {
  window.scrollTo(0, 0);

  // About Page Meta Tags
  document.title = 'About NMT Packaging Pvt Ltd | Premier Packaging Company in Mumbai & Maharashtra';

  const aboutMetaDescription = document.createElement('meta');
  aboutMetaDescription.name = 'description';
  aboutMetaDescription.content = 'Learn about NMT Packaging Pvt Ltd\'s journey and expertise in the packaging industry. With decades of experience, we provide high-quality corrugated packaging solutions in Mumbai, Pune, and Maharashtra. Discover our commitment to innovation, sustainability, and customer satisfaction.';
  document.head.appendChild(aboutMetaDescription);

  const aboutMetaKeywords = document.createElement('meta');
  aboutMetaKeywords.name = 'keywords';
  aboutMetaKeywords.content = 'Packaging Company Mumbai, About NMT Packaging Pvt Ltd, Packaging Industry Expertise Maharashtra, Packaging Solutions Pune, Corrugated Packaging Experts Mumbai, Sustainable Packaging Practices Maharashtra, NMT Packaging History, Leading Packaging Providers Pune';
  document.head.appendChild(aboutMetaKeywords);

  const aboutCanonicalLink = document.createElement('link');
  aboutCanonicalLink.rel = 'canonical';
  aboutCanonicalLink.href = 'https://nmt.eshikatech.com/about';
  document.head.appendChild(aboutCanonicalLink);

  const aboutOgTitle = document.createElement('meta');
  aboutOgTitle.property = 'og:title';
  aboutOgTitle.content = 'About NMT Packaging Pvt Ltd | Premier Packaging Company in Mumbai & Maharashtra';
  document.head.appendChild(aboutOgTitle);

  const aboutOgDescription = document.createElement('meta');
  aboutOgDescription.property = 'og:description';
  aboutOgDescription.content = 'Learn about NMT Packaging Pvt Ltd\'s journey and expertise in the packaging industry.';
  document.head.appendChild(aboutOgDescription);

  const aboutOgUrl = document.createElement('meta');
  aboutOgUrl.property = 'og:url';
  aboutOgUrl.content = 'https://nmt.eshikatech.com/about';
  document.head.appendChild(aboutOgUrl);

  const aboutOgSiteName = document.createElement('meta');
  aboutOgSiteName.property = 'og:site_name';
  aboutOgSiteName.content = 'NMT Packaging Pvt Ltd';
  document.head.appendChild(aboutOgSiteName);

  const aboutOgImage = document.createElement('meta');
  aboutOgImage.property = 'og:image';
  aboutOgImage.content = 'https://nmt.eshikatech.com/nmt4.png';
  document.head.appendChild(aboutOgImage);

  const aboutOgImageSecure = document.createElement('meta');
  aboutOgImageSecure.property = 'og:image:secure_url';
  aboutOgImageSecure.content = 'https://nmt.eshikatech.com/nmt4.png';
  document.head.appendChild(aboutOgImageSecure);

  const aboutOgImageWidth = document.createElement('meta');
  aboutOgImageWidth.property = 'og:image:width';
  aboutOgImageWidth.content = '300';
  document.head.appendChild(aboutOgImageWidth);

  const aboutOgImageHeight = document.createElement('meta');
  aboutOgImageHeight.property = 'og:image:height';
  aboutOgImageHeight.content = '200';
  document.head.appendChild(aboutOgImageHeight);

  // Structured Data for About Page
  const aboutStructuredData = document.createElement('script');
  aboutStructuredData.type = 'application/ld+json';
  aboutStructuredData.textContent = JSON.stringify({
    "@context": "https://schema.org",
    "@type": "AboutPage",
    "url": "https://nmt.eshikatech.com/about",
    "name": "NMT Packaging Pvt Ltd",
    "description": "Learn about NMT Packaging Pvt Ltd's journey and expertise in the packaging industry.",
    "publisher": {
      "@type": "Organization",
      "name": "NMT Packaging Pvt Ltd",
      "logo": {
        "@type": "ImageObject",
        "url": "https://nmt.eshikatech.com/nmt4.png"
      }
    }
  });
  document.head.appendChild(aboutStructuredData);

  return () => {
    document.head.removeChild(aboutMetaDescription);
    document.head.removeChild(aboutMetaKeywords);
    document.head.removeChild(aboutCanonicalLink);
    document.head.removeChild(aboutOgTitle);
    document.head.removeChild(aboutOgDescription);
    document.head.removeChild(aboutOgUrl);
    document.head.removeChild(aboutOgSiteName);
    document.head.removeChild(aboutOgImage);
    document.head.removeChild(aboutOgImageSecure);
    document.head.removeChild(aboutOgImageWidth);
    document.head.removeChild(aboutOgImageHeight);
    document.head.removeChild(aboutStructuredData);
  };

}, []);


  return (
    <div>

     < Banner/>
<Cl/>



    </div>
  )
}

export default About