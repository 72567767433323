import React from "react"
import classes from "./Ab2.module.css"
import a1 from "./../../Assest/g1.avif";
import a2 from "./../../Assest/g2.avif";
import a3 from "./../../Assest/g3.avif";
import Fade from 'react-reveal/Fade';

const Ab2 = () => {
    return (
        
    <>


<div className={classes.a1}>
 <Fade left>
<div  className={classes.a12}>
        <img src={a2} className={classes.img} />
</div>
</Fade>
<div className={classes.a11}>
    <Fade right>
<h3 className={classes.ih4}>Our Commitment</h3>
<p className={classes.ih41}>Our longstanding industry presence provides us with invaluable advantages, including direct access to paper mills and a robust paper distribution network. Managed by our dedicated family team, NMT Packaging Pvt Ltd is synonymous with reliability, quality, and customer satisfaction. Our strong relationships with suppliers and clients underscore our reputation as a trusted packaging partner.</p>

</Fade>
</div>

</div>



<div className={classes.a1}>

<div  className={classes.a11}>
     <Fade left>
    <h3 className={classes.ih4}>Our Goals</h3>
<p className={classes.ih41}><span className={classes.bed}>Technological Innovation:</span> Harnessing the latest technology to enhance packaging solutions.</p>
  <p className={classes.ih41}><span className={classes.bed}>Employee Engagement: </span>Cultivating a work environment based on trust and active participation.</p>
  <p className={classes.ih41}><span className={classes.bed}>Continuous Improvement: </span>Regular upgrades to technology and processes to maintain high standards.</p>
  <p className={classes.ih41}><span className={classes.bed}>Customer-Centric Approach: </span>Committed to exceptional service and adherence to industry regulations.</p>
  <p className={classes.ih41}><span className={classes.bed}>Global Expansion: </span>Targeting growth in emerging markets with cutting-edge infrastructure.
</p>
    </Fade>
</div>
<div className={classes.a12}>
        <Fade right>
    <img src={a1} className={classes.img} />
    </Fade>
</div>

</div>





<div className={classes.a1}>
 <Fade left>
<div  className={classes.a12}>
        <img src={a3} className={classes.img} />
</div>
</Fade>
<div className={classes.a11}>
    <Fade right>
<h3 className={classes.ih4}>Innovation and Quality</h3>
<p className={classes.ih41}>At NMT Packaging Pvt Ltd, our commitment to innovation drives us to continually evaluate and integrate the latest technologies. By upgrading our machinery and adopting advanced techniques, we ensure that our products meet the highest standards of quality and performance. Our technological edge enables us to provide superior packaging solutions that cater to the evolving needs of our clients while supporting our mission of excellence.</p>

</Fade>
</div>

</div>


        </>
    )
}

export default Ab2;