import React from 'react'
import classes from "./eleg.module.css";
import Fade from "react-reveal/Fade"

import a from './../../Assest/we1.png';
import b from './../../Assest/we2.png';
import c from './../../Assest/we3.png';
import d from './../../Assest/we4.png';

import b2 from './../../Assest/b2.avif';

const eleg = () => {
  return (
    <div>

<div className={classes.steps}>

<div className={classes.steps0}><Fade left >
<div className={classes.d1}>
  <h2 className={classes.ih4}>NMT Packaging Pvt Ltd</h2>
  <p className={classes.ih41}>Since 1969, NMT Packaging Pvt Ltd has been at the forefront of corrugated box manufacturing. Our journey from a small factory in Andheri (East) to a leading provider in Western India highlights our commitment to quality and innovation. With cutting-edge technology and a focus on sustainability, we offer packaging solutions that not only protect your products but also enhance their presentation. Explore our site to discover how we can support your packaging needs and become a part of our legacy of excellence.</p>
  <div className={classes.d}> <a href="tel:"><button className={classes.b1}> Call Us </button></a></div>
</div>
</Fade>
<div className={classes.d2}>
<Fade right >
  <img src={b2} className={classes.imgw} />
</Fade>
</div>
</div>


<div className={classes.ste1}>

     <h2 className={classes.ih3h}>Our Achivements</h2>


<div className={classes.steps1}>
  <Fade left >
<div className={classes.st1}>
  <img src={a} className={classes.imgs} />
  <h5 className={classes.ih3}>On-Time Deliveries</h5>
  <h6 className={classes.ih31}>
    Our vendor management system ensures 100% on-time deliveries
  </h6>
</div></Fade>
  <Fade left >
<div className={classes.st1}>
  <img src={b} className={classes.imgs} />
  <h5 className={classes.ih3}>ISO Certified</h5>
  <h6 className={classes.ih31}>
  ISO 9001 : 2008 & 9001 : 2015 Certified and Verified
  </h6>
</div>
</Fade>
  <Fade right >
<div className={classes.st1}>
  <img src={c} className={classes.imgs} />
  <h5 className={classes.ih3}>Green Level Certified</h5>
  <h6 className={classes.ih31}>
  Green Level certified by the SGS India Pvt. Ltd
  </h6>
</div></Fade>
<Fade right >
<div className={classes.st1}>
  <img src={d} className={classes.imgs} />
  <h5 className={classes.ih3}>Industry Recognition</h5>
  <h6 className={classes.ih31}>
  Consistent A+ ratings in supplier evaluations and audits.
  </h6>
</div></Fade>
</div>
</div>


</div>

    </div>
  )
}

export default eleg