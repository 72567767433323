import React from 'react'
import classes from "./Contact.module.css";
import Fade from 'react-reveal/Fade';
import a from "./../../Assest/email.png"
import b from "./../../Assest/phn.png"
import c from "./../../Assest/pin.png"

const Cnt = () => {
  return (
    <div className={classes.gblk}>
  
{/* <h3 className={classes.GLLd}>Contact Us</h3> */}





<div className={classes.cn}>
<Fade left>   


         <div className={classes.locationContainer}>
 
                <div className={classes.locationBox}>
                        <p className={classes.ico2}>Office</p>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1565.8161509717825!2d72.8653073183802!3d19.219940295119553!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b732f361dc25%3A0xdb427764a8b4c9d9!2sBlue%20Rose%20Ind.%20P.C.S%20Ltd!5e0!3m2!1sen!2sin!4v1730202036233!5m2!1sen!2sin" width="250" height="200" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" className={classes.loc}></iframe>
                </div>
<br/>
             
                <div className={classes.locationBox}>
                        <p className={classes.ico2}>Factory</p>
                 <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d30089.766339273312!2d73.03444618057175!3d19.4891343696158!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7a03c0a595ab9%3A0x48df0bab39850dc0!2sDakivali%2C%20Maharashtra%20421312!5e0!3m2!1sen!2sin!4v1730202211714!5m2!1sen!2sin" width="250" height="200" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" className={classes.loc}></iframe>
                </div>
            </div>
        
</Fade>








 
<Fade right>
    

<div className={classes.cn3}>
    <p className={classes.ico2}>Please Feel Free To Contact Us<br/>We Are Available For You</p>
          <form className={classes.qo5} action="https://formsubmit.co/info@shikatech.com" method="POST">
                        <input placeholder='ENTER YOUR NAME' className={classes.I1} name='name' id='name' type="text"></input><br />
                        <input placeholder='ENTER MOBILE NO' maxLength={10} className={classes.I1} name='phn' id='phn' type="text"></input><br />
                        <input placeholder='ENTER YOUR EMAIL ID' className={classes.I1} name='mail' id='mail' type="email"></input><br />
                        <textarea placeholder='DESCRIPTION' rows="4" className={classes.t1} name='des' id='des'></textarea><br />
                        <input type="submit" value="SUBMIT" className={classes.b1} />
                    </form>
</div>



{/* <div className={classes.cn2}>


<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3784.694010857791!2d73.7942993724072!3d18.452197182628087!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2950a58df56eb%3A0x1c8332e7a920b22b!2sAMBIONICS!5e0!3m2!1sen!2sin!4v1701341635893!5m2!1sen!2sin"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" className={classes.srs}></iframe>
</div> */}
</Fade>
</div>
        
    </div>
  )
}

export default Cnt


                  

     


