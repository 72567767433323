
import React from 'react'
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import logo from './../../Assest/lo1.png';

export default function Wpp() {
    return (
        <div className="App">
            <FloatingWhatsApp
                phoneNumber="1234567890"
                accountName="NMT Packaging Pvt Ltd"
                avatar={logo}
                chatMessage="We Are Here To Solve Your Queries, Please Ask Freely."
                allowEsc
                allowClickAway
                notification
                notificationSound
            />
        </div>
    );
}