import React from 'react';
import { useState } from "react";
import  classes from "./Navi.module.css";


import  {Link}  from 'react-router-dom';
import { FaHouseDamage, FaAddressCard, FaList, FaAddressBook, FaInstagram, FaFacebook, FaLinkedin, FaWhatsapp } from "react-icons/fa";
import logo2 from './../../Assest/nmt1.png';

function Navi() {
  const [active, setActive] = useState("nav__menu");
  const [icon, setIcon] = useState("nav__toggler");
  const navToggle = () => {
    if (active === "nav__menu") {
      setActive("nav__menu nav__active");
    } else setActive("nav__menu");

    // Icon Toggler
    if (icon === "nav__toggler") {
      setIcon("nav__toggler toggle");
    } else setIcon("nav__toggler");
  };

    const resetNavbar = () => {
      setActive('nav__menu');
      setIcon('nav__toggler');
   
  };
  return (
    <div className={classes.nasv}>



    <nav className={classes.nav}>
     
     <div className={classes.logo2}>
      <img src={logo2} className={classes.img1}></img><br/>
      <p className={classes.nb}>NMT Packaging Pvt Ltd</p>
     </div>     

<div>

{/* <span className={classes.or}>S</span>RI <span className={classes.or}>&nbsp;B</span>ALAJEE INDUSTRIAL 

<p className={classes.nb}>NMT Packaging Pvt Ltd</p> */}
<div>

</div>

<br></br>
<div className={classes.nd}>

<div className={active}>
      <ul className={classes.ul}>
      <li  className={classes.list}><Link to='/' className={classes.list} onClick={resetNavbar}>Home</Link></li>
      <li  className={classes.list}><Link to='/about' className={classes.list} onClick={resetNavbar}>About</Link></li>
      <li  className={classes.list}><Link to='/clientele' className={classes.list} onClick={resetNavbar}>Clientele</Link></li>
        <li  className={classes.list}><Link to='/machinery' className={classes.list} onClick={resetNavbar}>Machinery</Link></li>
        <li className={classes.list}><Link to='/products' className={classes.list} onClick={resetNavbar}>Product</Link></li>
        <li  className={classes.list}><Link to='/contact' className={classes.list} onClick={resetNavbar}>Contact</Link></li>
    
                    <button className={classes.startProjectButton}>Our Company Profile</button>
               

      </ul>
      </div>
      </div>
      </div>

  


      <div onClick={navToggle} className={icon}>
        <div className="line1"></div>
        <div className="line2"></div>
        <div className="line3"></div>
      </div>
    </nav>
    </div>
  );
}

export default Navi;