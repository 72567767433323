import React from 'react'
import classes from './aboutbanner.module.css'

const aboutbanner = () => {
  return (
    <div className={classes.ste1}>

      Our Machinery
    </div>
  )
}

export default aboutbanner