import React from 'react'
import classes from "./Ab1.module.css"
import img1 from "./../../Assest/b3.avif"
import a2 from "./../../Assest/g2.avif";
import Fade from 'react-reveal/Fade';

const Ab1 = () => {
  return (
    <div>
 
 
<div className={classes.steps}>

<div className={classes.steps0}>
<div className={classes.d1}>
     
  <h2 className={classes.ih4}>Our Story</h2>
  {/* <p className={classes.ih41}>We Are Trusted By More Than 10+ Clients </p> */}
</div>
</div>


<div className={classes.steps1}>
<div className={classes.d1}> <Fade left>
  <p className={classes.ih41}>
    Our founder Mr B.J.Purohit started of the business of manufacturing corrugated box factory at 
Andheri (East), M.I.D.C., area in the year 1969 for industrial packaging purposes under the 
name As the company operations grew larger new factories were 
started, one at Baroda (Gujarat), second at Palghar in the years 1981 and 1986 respectively. In 
1997, a new factory, fully automatic was started at Dakivali Village, Taluka Wada, Dist.: 
Palghar (Maharashtra) with a new name NMT PACKAGING PRIVATE LIMITED . We therefore 
now have a fairly good presence in the western India sector
  </p></Fade>
</div>

<div className={classes.d2}> 
<Fade right>

<div className={classes.imgg}><img src={img1} className={classes.imgg} /></div>
</Fade>

</div>
</div>

<div className={classes.a1}>
 <Fade left>
<div  className={classes.a12}>
        <img src={a2} className={classes.img} />
</div>
</Fade>
<br/><br/>

</div>
</div>


    </div>
  )
}

export default Ab1