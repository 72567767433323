import React, { useEffect } from 'react';
import Product from "./../Component/Products/Products";
import Banner from "./../Component/Products/aboutbanner";
import P1 from "./../Component/Home/P1"
import P2 from "./../Component/Home/P2"
import P3 from "./../Component/Home/P3"
import P4 from "./../Component/Home/P4"
import P5 from "./../Component/Home/P5"

const Products = () => {
 useEffect(() => {
  window.scrollTo(0, 0);

  // Products Page Meta Tags
  document.title = 'Packaging Products by NMT Packaging Pvt Ltd | Custom Solutions in Mumbai & Maharashtra';

  const productsMetaDescription = document.createElement('meta');
  productsMetaDescription.name = 'description';
  productsMetaDescription.content = 'Explore the diverse range of packaging products from NMT Packaging Pvt Ltd. We offer custom corrugated boxes, eco-friendly solutions, and industry-specific packaging in Mumbai, Pune, and Maharashtra. Find high-quality packaging for e-commerce, FMCG, food & beverages, and more.';
  document.head.appendChild(productsMetaDescription);

  const productsMetaKeywords = document.createElement('meta');
  productsMetaKeywords.name = 'keywords';
  productsMetaKeywords.content = 'Custom Packaging Mumbai, Corrugated Boxes Maharashtra, E-Commerce Packaging Solutions Pune, FMCG Packaging Mumbai, Food & Beverage Packaging Maharashtra, High-Quality Packaging Products Pune, Durable Packaging Solutions Mumbai, Eco-Friendly Packaging Maharashtra, Pharma Packaging Pune, Industrial Packaging Solutions Mumbai';
  document.head.appendChild(productsMetaKeywords);

  const productsCanonicalLink = document.createElement('link');
  productsCanonicalLink.rel = 'canonical';
  productsCanonicalLink.href = 'https://nmt.eshikatech.com/products';
  document.head.appendChild(productsCanonicalLink);

  const productsOgTitle = document.createElement('meta');
  productsOgTitle.property = 'og:title';
  productsOgTitle.content = 'Packaging Products by NMT Packaging Pvt Ltd | Custom Solutions in Mumbai & Maharashtra';
  document.head.appendChild(productsOgTitle);

  const productsOgDescription = document.createElement('meta');
  productsOgDescription.property = 'og:description';
  productsOgDescription.content = 'Explore the diverse range of packaging products from NMT Packaging Pvt Ltd. We offer custom corrugated boxes, eco-friendly solutions, and industry-specific packaging.';
  document.head.appendChild(productsOgDescription);

  const productsOgUrl = document.createElement('meta');
  productsOgUrl.property = 'og:url';
  productsOgUrl.content = 'https://nmt.eshikatech.com/products';
  document.head.appendChild(productsOgUrl);

  const productsOgSiteName = document.createElement('meta');
  productsOgSiteName.property = 'og:site_name';
  productsOgSiteName.content = 'NMT Packaging Pvt Ltd';
  document.head.appendChild(productsOgSiteName);

  const productsOgImage = document.createElement('meta');
  productsOgImage.property = 'og:image';
  productsOgImage.content = 'https://nmt.eshikatech.com/nmt4.png';
  document.head.appendChild(productsOgImage);

  const productsOgImageSecure = document.createElement('meta');
  productsOgImageSecure.property = 'og:image:secure_url';
  productsOgImageSecure.content = 'https://nmt.eshikatech.com/nmt4.png';
  document.head.appendChild(productsOgImageSecure);

  const productsOgImageWidth = document.createElement('meta');
  productsOgImageWidth.property = 'og:image:width';
  productsOgImageWidth.content = '300';
  document.head.appendChild(productsOgImageWidth);

  const productsOgImageHeight = document.createElement('meta');
  productsOgImageHeight.property = 'og:image:height';
  productsOgImageHeight.content = '200';
  document.head.appendChild(productsOgImageHeight);

  // Structured Data for Products Page
  const productsStructuredData = document.createElement('script');
  productsStructuredData.type = 'application/ld+json';
  productsStructuredData.textContent = JSON.stringify({
    "@context": "https://schema.org",
    "@type": "Product",
    "url": "https://nmt.eshikatech.com/products",
    "name": "Packaging Products",
    "description": "Explore the diverse range of packaging products from NMT Packaging Pvt Ltd. We offer custom corrugated boxes and eco-friendly solutions.",
    "publisher": {
      "@type": "Organization",
      "name": "NMT Packaging Pvt Ltd",
      "logo": {
        "@type": "ImageObject",
        "url": "https://nmt.eshikatech.com/nmt4.png"
      }
    }
  });
  document.head.appendChild(productsStructuredData);

  return () => {
    document.head.removeChild(productsMetaDescription);
    document.head.removeChild(productsMetaKeywords);
    document.head.removeChild(productsCanonicalLink);
    document.head.removeChild(productsOgTitle);
    document.head.removeChild(productsOgDescription);
    document.head.removeChild(productsOgUrl);
    document.head.removeChild(productsOgSiteName);
    document.head.removeChild(productsOgImage);
    document.head.removeChild(productsOgImageSecure);
    document.head.removeChild(productsOgImageWidth);
    document.head.removeChild(productsOgImageHeight);
    document.head.removeChild(productsStructuredData);
  };

}, []);


  return (
    <div>
      <Banner/>
      <P1/><P2/><P3/><P4/><P5/>
      {/* <Product /> */}
      </div>
  );
}

export default Products;
