import React from 'react';
import styles from './vm.module.css';
import P1 from "./../../Assest/vm1.png"
import Fade from 'react-reveal/Fade';
import P2 from "./../../Assest/vm2.webp";

const MonoCartons = () => {
  return (
    <div className={styles.bgPurple}>
      <div className={`${styles.container} ${styles.flex}`}>
          <div className={styles.textContainer}> <Fade left>
              <div className={styles.imageContainer}> 
          <img
            alt="Fumigated Corrugated Box"
            className={styles.rotatedImage}
            height="200"
           src={P2}
            width="200"
          />
          </div>
            
          <h1 className={styles.heading1}>Our Mission</h1>
          <p className={styles.paragraph}>
  To fulfill the needs of consumers and work to increase their happiness by continuously improving processes and products
          </p>
    </Fade>
        </div>





        <div className={styles.textContainer}>
           <Fade right>
             <div className={styles.imageContainer}> 
          <img
            alt="Fumigated Corrugated Box"
            className={styles.rotatedImage}
            height="150"
           src={P1}
            width="150"
          />
          </div>
          <h1 className={styles.heading}>Our Vision</h1>
          <p className={styles.paragraph}>
       To reach the pinnacle of the packaging industry with happy clients worldwide is Our promisable Vision
</p></Fade>
        </div>
      </div>
    </div>
  );
};

export default MonoCartons;
