import React,{useEffect} from 'react'
import Contacts1 from "./../Component/Contact/Contact1"
import Contacts from "./../Component/Contact/contact"
import Banner from "./../Component/Contact/aboutbanner"

const Contact = () => {
useEffect(() => {
  window.scrollTo(0, 0);

  // Contact Page Meta Tags
  document.title = 'Contact NMT Packaging Pvt Ltd | Get in Touch with Mumbai’s Top Packaging Company';

  const contactMetaDescription = document.createElement('meta');
  contactMetaDescription.name = 'description';
  contactMetaDescription.content = 'Get in touch with NMT Packaging Pvt Ltd for all your packaging needs. Whether you’re interested in our products or need more information about our services in Mumbai, Pune, and Maharashtra, our team is here to help. Contact us today to discuss your packaging solutions.';
  document.head.appendChild(contactMetaDescription);

  const contactMetaKeywords = document.createElement('meta');
  contactMetaKeywords.name = 'keywords';
  contactMetaKeywords.content = 'Contact NMT Packaging Mumbai, Packaging Services Inquiry Pune, Get in Touch with NMT Packaging Maharashtra, Packaging Solutions Contact Mumbai, Customer Service Packaging Maharashtra, Packaging Quote Request Pune, Contact Information NMT Packaging Mumbai, Packaging Support Maharashtra';
  document.head.appendChild(contactMetaKeywords);

  const contactCanonicalLink = document.createElement('link');
  contactCanonicalLink.rel = 'canonical';
  contactCanonicalLink.href = 'https://nmt.eshikatech.com/contact';
  document.head.appendChild(contactCanonicalLink);

  const contactOgTitle = document.createElement('meta');
  contactOgTitle.property = 'og:title';
  contactOgTitle.content = 'Contact NMT Packaging Pvt Ltd | Get in Touch with Mumbai’s Top Packaging Company';
  document.head.appendChild(contactOgTitle);

  const contactOgDescription = document.createElement('meta');
  contactOgDescription.property = 'og:description';
  contactOgDescription.content = 'Get in touch with NMT Packaging Pvt Ltd for all your packaging needs.';
  document.head.appendChild(contactOgDescription);

  const contactOgUrl = document.createElement('meta');
  contactOgUrl.property = 'og:url';
  contactOgUrl.content = 'https://nmt.eshikatech.com/contact';
  document.head.appendChild(contactOgUrl);

  const contactOgSiteName = document.createElement('meta');
  contactOgSiteName.property = 'og:site_name';
  contactOgSiteName.content = 'NMT Packaging Pvt Ltd';
  document.head.appendChild(contactOgSiteName);

  const contactOgImage = document.createElement('meta');
  contactOgImage.property = 'og:image';
  contactOgImage.content = 'https://nmt.eshikatech.com/nmt4.png';
  document.head.appendChild(contactOgImage);

  const contactOgImageSecure = document.createElement('meta');
  contactOgImageSecure.property = 'og:image:secure_url';
  contactOgImageSecure.content = 'https://nmt.eshikatech.com/nmt4.png';
  document.head.appendChild(contactOgImageSecure);

  const contactOgImageWidth = document.createElement('meta');
  contactOgImageWidth.property = 'og:image:width';
  contactOgImageWidth.content = '300';
  document.head.appendChild(contactOgImageWidth);

  const contactOgImageHeight = document.createElement('meta');
  contactOgImageHeight.property = 'og:image:height';
  contactOgImageHeight.content = '200';
  document.head.appendChild(contactOgImageHeight);

  // Structured Data for Contact Page
  const contactStructuredData = document.createElement('script');
  contactStructuredData.type = 'application/ld+json';
  contactStructuredData.textContent = JSON.stringify({
    "@context": "https://schema.org",
    "@type": "ContactPage",
    "url": "https://nmt.eshikatech.com/contact",
    "name": "Contact NMT Packaging Pvt Ltd",
    "description": "Get in touch with NMT Packaging Pvt Ltd for all your packaging needs.",
    "contactPoint": {
      "@type": "ContactPoint",
      "telephone": "+91-1234567890",
      "contactType": "Customer Service",
        "logo": {
        "@type": "ImageObject",
        "url": "https://nmt.eshikatech.com/nmt4.png"
      }
      
    }
  });
  document.head.appendChild(contactStructuredData);

  return () => {
    document.head.removeChild(contactMetaDescription);
    document.head.removeChild(contactMetaKeywords);
    document.head.removeChild(contactCanonicalLink);
    document.head.removeChild(contactOgTitle);
    document.head.removeChild(contactOgDescription);
    document.head.removeChild(contactOgUrl);
    document.head.removeChild(contactOgSiteName);
    document.head.removeChild(contactOgImage);
    document.head.removeChild(contactOgImageSecure);
    document.head.removeChild(contactOgImageWidth);
    document.head.removeChild(contactOgImageHeight);
    document.head.removeChild(contactStructuredData);
  };

}, []);


  return (
    <div>
      <Banner/>
      <Contacts1/>
<Contacts/>

    </div>
  )
}

export default Contact