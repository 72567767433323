import React, { useState, useEffect } from 'react';
import styles from './Counter.module.css';
import Fade from 'react-reveal/Fade';

const Counters = () => {
  const [count1, setCount1] = useState(0);
  const [count2, setCount2] = useState(0);
  const [count3, setCount3] = useState(0);

  useEffect(() => {
    let timer1;
    if (count1 < 270) {
      timer1 = setTimeout(() => {
        setCount1(count1 + 3);
      }, 80); // Increment every 10 milliseconds
    }
    return () => clearTimeout(timer1);
  }, [count1]);

  useEffect(() => {
    let timer2;
    if (count2 < 100000) {
      timer2 = setTimeout(() => {
        setCount2(count2 + 5);
      }, 0); // Increment every 10 milliseconds
    }
    return () => clearTimeout(timer2);
  }, [count2]);

  useEffect(() => {
    let timer3;
    if (count3 < 65) {
      timer3 = setTimeout(() => {
        setCount3(count3 + 1);
      }, 80); // Increment every 10 milliseconds
    }
    return () => clearTimeout(timer3);
  }, [count3]);

  return (
    <div className={styles.container}>
      <div className={styles.cnt}>
        <Fade left>
        <span className={styles.counterValue}>{count1}</span>
         <h1 className={styles.heading}> Happy Customers  </h1>
         </Fade>
      </div>
       <div className={styles.cnt}>
        <Fade>
        <span className={styles.counterValue}>{count2}</span>
         <h1 className={styles.heading}> Boxes per Day  </h1>
         </Fade>
      </div>
        <div className={styles.cnt}>
           <Fade right>
        <span className={styles.counterValue}>{count3}</span>
         <h1 className={styles.heading}> Years  Experience </h1>
         </Fade>
      </div>
    </div>
  );
};

export default Counters;