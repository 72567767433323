import React from 'react';
import styles from './P5.module.css';
import P from "./../../Assest/p5.webp"
import Fade from 'react-reveal/Fade';

const MonoCartons = () => {
  return (
    <div className={styles.bgPurple}>
      <div className={`${styles.container} ${styles.flex}`}><Fade left>
        
  <div className={styles.imageContainer}> 
          <img
            alt="Two perfume boxes, one purple labeled 'MIDNIGHT' and one green labeled 'COLOGNE'"
            className={styles.rotatedImage}
            height="300"
            // src="https://storage.googleapis.com/a1aa/image/GVvsK9nOGaL1MNOyOXQLe4IsQTvcrwuqv2IWRQhs1fmpGxoTA.jpg"
            src={P}
            width="300"
          />
        </div></Fade>

        <div className={styles.textContainer}> <Fade right>
          <h1 className={styles.heading}>Box with Partitions</h1>
          <p className={styles.paragraph}>
           Discover NMT Packaging Pvt Ltd's boxes with partitions, expertly designed to keep your items organized and secure. Each box features customizable partitions, allowing you to separate products while maximizing space. Made from high-quality materials, they provide durability and protection during transit. Ideal for fragile or diverse products, these boxes ensure safe storage and transport. Trust NMT Packaging for partitioned boxes that meet your packaging needs with precision and style.   </p>
          <div className={styles.buttonContainer}>
              <button className={styles.startProjectButton}>Learn More</button>
      
          </div></Fade>
        </div>

      
      </div>
    </div>
  );
};

export default MonoCartons;
