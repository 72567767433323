import React from 'react';
import styles from './sfy.module.css';
import P from "./../../Assest/65years.jpg"
import Fade from 'react-reveal/Fade';

const MonoCartons = () => {
  return (
    <div className={styles.bgPurple}>
      <div className={`${styles.container} ${styles.flex}`}>
         <Fade left>
        <div className={styles.imageContainer}>
          <img
            alt="Fumigated Corrugated Box"
            className={styles.rotatedImage}
            height="300"
           src={P}
            width="300"
          />
        </div> </Fade>
        <div className={styles.textContainer}>

              <Fade right>
          <h1 className={styles.heading}>65+ Years Completed</h1>
          <p className={styles.paragraph}>
           NMT Packaging Pvt Ltd is pleased to commemorate more than 65 years of packaging industry innovation and commitment. Our dedication to quality, client happiness, and ongoing development has characterized our path. We are grateful to our devoted clients and esteemed partners for sharing in our journey. 
          </p>
          <div className={styles.buttonContainer}>
          <button className={styles.startProjectButton}>Learn More</button>
           
          </div></Fade>
        </div>
      </div>
    </div>
  );
};

export default MonoCartons;
