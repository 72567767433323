import React from 'react';
import classes from './Cert.module.css'; 
import a from "./../../Assest/iso8.png"
import b from "./../../Assest/fsc.webp"
import c from "./../../Assest/iso15.png"

const Images = () => {
    return (
        <div className={classes.imageContainer}>
            <img className={classes.image} src={a} alt="Iso certified" />
            <img className={classes.image2} src={b} alt="FSC certified" />
            <img className={classes.image} src={c} alt="Iso certified" />
        </div>
    );
};

export default Images;
