import React from 'react';
import styles from './P4.module.css';
import P from "./../../Assest/p4.webp"
import Fade from 'react-reveal/Fade';

const MonoCartons = () => {
  return (
    <div className={styles.bgPurple}>
      <div className={`${styles.container} ${styles.flex}`}><Fade left>
        
        <div className={styles.textContainer}> 
          <h1 className={styles.heading}>Interlocking Boxes</h1>
          <p className={styles.paragraph}>
           Introducing NMT Packaging Pvt Ltd's interlocking boxes are intended for easy assembly and reliable protection. These innovative boxes have a unique interlocking design that improves stability while reducing the need for adhesives. They are made of long-lasting materials and assure the safety of your products during transportation and storage. They are ideal for a variety of applications and offer an environmentally beneficial packaging solution. NMT Packaging offers interlocking boxes that combine efficiency and robustness to meet your company demands.
  </p>
          <div className={styles.buttonContainer}>
           <button className={styles.startProjectButton}>Learn More</button>
           
          </div>
        </div></Fade>
<Fade right>
        <div className={styles.imageContainer}> 
          <img
            alt="Two perfume boxes, one purple labeled 'MIDNIGHT' and one green labeled 'COLOGNE'"
            className={styles.rotatedImage}
            height="300"
            // src=
            src={P}
            width="300"
          />
        </div></Fade>
      </div>
    </div>
  );
};

export default MonoCartons;
