
import classes from './footer1.module.css'; // Import the CSS module
import car from "./../../Assest/tm.gif"
import tempo from "./../../Assest/t1.gif"
import birds from "./../../Assest/birds.gif"
import fb from './../../Assest/facebook (1).png';
import insta from './../../Assest/social.png';
import logo2 from './../../Assest/lo1d.png';
import Heart from "./../../Assest/eziigj.gif"
import { FaHouseDamage, FaAddressCard, FaList, FaAddressBook,FaWhatsapp, FaInstagram, FaFacebook, FaLinkedin } from "react-icons/fa";
import lotus from './../../Assest/nmt7.png';

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Fade from "react-reveal/Fade"
import { useRef } from 'react';



const Footer = () => {
    return (
        <>
            <footer className={classes.footer}>
                <img src={birds} alt="Birds" className={classes.birds} />

                {/* <div className={classes.content}>
                <div className={classes.column}>
                    <div className={classes.row1}>
                        <div className={classes.logo}><img src={logo} alt='NMT Packaging Pvt Ltd'></img></div>
                        <div className={classes.address}><p>
                            Unit No.215, 2nd Floor, Blue Rose Indl. Estate, W.E. Highway, Opp. Magathane Bus Deport, Borivali (E), Mumbai - 400 066.</p></div>
                        <div className={classes.icon}>
                            <a href="#"> <img src={insta} alt='NMT Packaging Pvt Ltd' /> </a>
                            <a href="#"> <img src={fb} alt='NMT Packaging Pvt Ltd' /> </a>

                        </div>
                    </div>
                </div>

                <div className={classes.column}>   <div className={classes.row2}>
                    <h3 className={classes.kd}>Quick Links</h3>
                    <div className={classes.lnk1}>
                        <span><Link to='/' className={classes.lnk}>Home</Link></span>
                        <span><Link to='/about' className={classes.lnk}>About</Link></span>
                        <span><Link to='/machinary' className={classes.lnk}>Machinery</Link></span>
                        <span><Link to='/products' className={classes.lnk}>Products</Link></span>
                        <span><Link to='/Contact' className={classes.lnk}>Contact</Link></span>
                    </div></div>
                </div>

                <div className={classes.column}><div className={classes.row3}>
                    <h3 className={classes.kd}>Connect Us</h3>
                    <div className={classes.lnk1}>
                        <span><a className={classes.lnk} href='mailto:#'>info@nmtpvtltd.com </a></span>
                        <br />
                        <span><a className={classes.lnk} href='tel:#'>+91 1234567890</a>
                            <br />
                        </span>
                    </div>
                </div></div>


                <div className={classes.column}> <div className={classes.row4}>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d60273.9471039805!2d72.79799360749725!3d19.233527423382274!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b0ce3cd117f7%3A0x515d558b955bf692!2sBorivali%20West%2C%20Mumbai%2C%20Maharashtra!5e0!3m2!1sen!2sin!4v1724161354163!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div></div>

            </div> */}


                <Fade right>
                    <div className={classes.ftr2} >


                        <div className={classes.ftr22}>

                            <div className={classes.c1}>

                                <h2 className={classes.h3}> Social </h2>
<br/><br/><br/>
                                <br />
                                <div className={classes.logo2}>

                                    <img src={lotus} className='lo'></img>


                                </div>


                                <br />
                                <div className={classes.yz}>

                                    <FaInstagram className={classes.icon3} />
                                    <FaFacebook className={classes.icon3} />
                                    <FaLinkedin className={classes.icon3} />
                                       <FaWhatsapp className={classes.icon3} />


                                </div>
                                <br />

                                {/* <h4 className={classes.h4}> Let's Rock And Roll Together</h4> */}
                                {/* 
                        <div className={classes.yz}>
                            <a href='tel:+919967190248'><button className={classes.hif}>Get In Touch</button></a>
                        </div> */}

                            </div>


                            <div className={classes.c1}>

                                <h2 className={classes.h3}> Quick links </h2>

                                <div className={classes.hu}>
                                    <li><a href="/ " className={classes.apal}>Home</a></li>
                                    <li><a href="/about" className={classes.apal}>About</a></li>
                                    <li><a href="/clientele" className={classes.apal}>Clientele</a></li>
                                    <li><a href="/machinery" className={classes.apal}>Machinery</a></li>
                                    <li><a href="/products" className={classes.apal}>Product</a></li>
                                    <li><a href="/contact" className={classes.apal}>Contact</a></li>

                                </div>

                            </div>

                            <div className={classes.c2}>

                                <h2 className={classes.h3}>Office</h2>
                                {/* <div className={classes.hu}>
                            <li><a href="/service" className={classes.apal}>Website Designing</a></li>
                            <li><a href="/service" className={classes.apal}>Website Development</a></li>
                            <li><a href="/service" className={classes.apal}>Responsive Webapp</a></li>
                            <li><a href="/service" className={classes.apal}>Domain & Hosting</a></li>
                            <li><a href="/service" className={classes.apal}>C M S</a></li>

                        

                        </div>  */}
                                <div className={classes.hu1}>
                                    {/* <span className={classes.apal2}>Office Address</span> */}
                                    {/* <br /> */}
                                    <span className={classes.apaldd}>215,Blue Rose Industiral Estate, Wastern Express Highway, Borivali East, Next To Metro Cash And Carry, 400066</span>
                                    <br /> <br /></div>
                            </div>



                            <div className={classes.c2}>

                                <h2 className={classes.h3}> Factory</h2>

                                <div className={classes.hu1}>

                                    {/* <span className={classes.apal2}>Factory Address</span> */}
                                    {/* <br /> */}
                                    <span className={classes.apaldd}>NMT Packaging Pvt Ltd, Gut No-293, Dakivali Village, Wada Taluk, Palghar, 421312</span>
                                    <br /> <br />


                                </div>
                            </div>


                            <div className={classes.c1}>

                                <h2 className={classes.h3}> Contact Us</h2>
                           
                                <div className={classes.hu}>
                                    <span className={classes.apal32}>
                                        <span className={classes.apal}>Email :</span><br />
                                        <span className={classes.apal}><a href='mailto:info@eshikatech.com' className={classes.fd}>info@nmtpackaging.com</a></span>

                                        <br />

                                        <span className={classes.apal}>Phone :</span><br />
                                        <span className={classes.apal}><a href='tel:+91' className={classes.fd}>+91 1234567890</a></span>
                                    </span>
                                </div>

                            </div>


                        </div>

                    </div>

                </Fade>

                <img src={car} alt="Car" className={classes.car} />

                <img src={tempo} alt="Tempo" className={classes.tempo} />



            </footer>

            <footer2>

                <div className={classes.bottom}>
                    <p>Copyright © 2024 NMT Packaging Pvt Ltd |  Powered by <a href="https://eshikatech.com" className={classes.ad}> EshikaTech Pvt Ltd</a></p>
                </div>
            </footer2>

        </>
    );
};

export default Footer;
