import classes from "./Header.module.css";
import image from "./../../Assest/message.png";
import image2 from "./../../Assest/ringphone.png";
import { FaHouseDamage, FaAddressCard, FaList, FaAddressBook, FaInstagram, FaFacebook, FaLinkedin, FaWhatsapp } from "react-icons/fa";
function Header() {
    return (
        <div className={classes.overall}>

         
                <div className={classes.socialIcons}>
                 
                            <FaInstagram className={classes.icon3} />
                            <FaFacebook className={classes.icon3} />
                            <FaLinkedin className={classes.icon3} />
                              <FaWhatsapp className={classes.icon3} />
                </div>
                <div className={classes.contactInfo}>
                     1234567890 / 1234567890
                </div>
            </div>

      
    );
};
export default Header; 