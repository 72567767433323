import React from 'react';
import styles from './P1.module.css';
import P from "./../../Assest/p1.webp"
import Fade from 'react-reveal/Fade';
 <Fade left></Fade>
const MonoCartons = () => {
  return (
    <div className={styles.bgPurple}>
      <div className={`${styles.container} ${styles.flex}`}> <Fade left>
        <div className={styles.imageContainer}>
          
          <img
            alt="Fumigated Corrugated Box"
            className={styles.rotatedImage}
            height="300"
           src={P}
            width="300"
          />
        </div></Fade>
        <div className={styles.textContainer}>
           <Fade right>
          <h1 className={styles.heading}>Fumigated Corrugated Box</h1>
          <p className={styles.paragraph}>
            Introducing NMT Packaging Pvt Ltd's fumigated corrugated boxes, designed for maximum protection throughout transportation. Our thorough fumigation method effectively eliminates pests, keeping your products safe and compliant. These boxes, made from high-quality materials, are exceptionally strong and durable. They are ideal for international exports and comply with all applicable rules. Trust NMT Packaging to protect your items with our dependable fumigation solutions.
          </p>
          </Fade>
          <div className={styles.buttonContainer}>
        <button className={styles.startProjectButton}>Learn More</button>
          
          </div>
        </div>
      </div>
    </div>
  );
};

export default MonoCartons;
