import React from 'react'
import classes from "./Ab3.module.css"

import img5 from "./../../Assest/xz1.png"
import img6 from "./../../Assest/xz2.png"
import img7 from "./../../Assest/xz3.png"
import img8 from "./../../Assest/xz4.png"
import Fade from "react-reveal/Fade"

const Benifits = () => {
  return (
    <div>

<br/><br/>

<div className={classes.pro1}>

<Fade left>
<div className={classes.pro}>
  <img src={img5} className={classes.img} /> 
  <h4 className={classes.h4}>Excellent Quality</h4>
</div></Fade>
<Fade left>
<div className={classes.pro}>
  <img src={img6} className={classes.img} /> 
  <h4 className={classes.h4}>On Time Delivery</h4>
</div></Fade>
<Fade right>
<div className={classes.pro}>
  <img src={img7} className={classes.img} /> 
  <h4 className={classes.h4}>Broad Range</h4>
</div></Fade>
<Fade right>
<div className={classes.pro}>
  <img src={img8} className={classes.img} /> 
  <h4 className={classes.h4}>Motivated Team</h4>
</div></Fade>




</div>



    </div>
  )
}

export default Benifits