import React, { useEffect } from 'react';
import Servicess from "./../Component/Machinary/Machinary";
import Servicess1 from "./../Component/Machinary/Machinary1";
import Banner from "./../Component/Machinary/aboutbanner";

const Services = () => {
useEffect(() => {
  window.scrollTo(0, 0);

  // Machinery & Technology Page Meta Tags
  document.title = 'Advanced Machinery & Technology at NMT Packaging Pvt Ltd | Mumbai & Maharashtra';

  const machineryMetaDescription = document.createElement('meta');
  machineryMetaDescription.name = 'description';
  machineryMetaDescription.content = 'Discover the cutting-edge machinery and technology at NMT Packaging Pvt Ltd. Our advanced packaging equipment in Mumbai, Pune, and Maharashtra ensures superior quality and efficiency. Explore our high-speed auto plants, flexo rotary printers, and more for top-notch packaging solutions.';
  document.head.appendChild(machineryMetaDescription);

  const machineryMetaKeywords = document.createElement('meta');
  machineryMetaKeywords.name = 'keywords';
  machineryMetaKeywords.content = 'Packaging Machinery Mumbai, Advanced Packaging Technology Maharashtra, High-Speed Auto Plants Pune, Flexo Rotary Printers Mumbai, Packaging Equipment Maharashtra, Innovative Packaging Machinery Pune, Corrugated Packaging Technology Mumbai, Latest Packaging Solutions Maharashtra, Auto Bundling Machines Pune, High-Quality Packaging Equipment Mumbai';
  document.head.appendChild(machineryMetaKeywords);

  const machineryCanonicalLink = document.createElement('link');
  machineryCanonicalLink.rel = 'canonical';
  machineryCanonicalLink.href = 'https://nmt.eshikatech.com/machinery';
  document.head.appendChild(machineryCanonicalLink);

  const machineryOgTitle = document.createElement('meta');
  machineryOgTitle.property = 'og:title';
  machineryOgTitle.content = 'Advanced Machinery & Technology at NMT Packaging Pvt Ltd | Mumbai & Maharashtra';
  document.head.appendChild(machineryOgTitle);

  const machineryOgDescription = document.createElement('meta');
  machineryOgDescription.property = 'og:description';
  machineryOgDescription.content = 'Discover the cutting-edge machinery and technology at NMT Packaging Pvt Ltd. Our advanced packaging equipment ensures superior quality and efficiency.';
  document.head.appendChild(machineryOgDescription);

  const machineryOgUrl = document.createElement('meta');
  machineryOgUrl.property = 'og:url';
  machineryOgUrl.content = 'https://nmt.eshikatech.com/machinery';
  document.head.appendChild(machineryOgUrl);

  const machineryOgSiteName = document.createElement('meta');
  machineryOgSiteName.property = 'og:site_name';
  machineryOgSiteName.content = 'NMT Packaging Pvt Ltd';
  document.head.appendChild(machineryOgSiteName);

  const machineryOgImage = document.createElement('meta');
  machineryOgImage.property = 'og:image';
  machineryOgImage.content = 'https://nmt.eshikatech.com/nmt4.png';
  document.head.appendChild(machineryOgImage);

  const machineryOgImageSecure = document.createElement('meta');
  machineryOgImageSecure.property = 'og:image:secure_url';
  machineryOgImageSecure.content = 'https://nmt.eshikatech.com/nmt4.png';
  document.head.appendChild(machineryOgImageSecure);

  const machineryOgImageWidth = document.createElement('meta');
  machineryOgImageWidth.property = 'og:image:width';
  machineryOgImageWidth.content = '300';
  document.head.appendChild(machineryOgImageWidth);

  const machineryOgImageHeight = document.createElement('meta');
  machineryOgImageHeight.property = 'og:image:height';
  machineryOgImageHeight.content = '200';
  document.head.appendChild(machineryOgImageHeight);

  // Structured Data for Machinery Page
  const machineryStructuredData = document.createElement('script');
  machineryStructuredData.type = 'application/ld+json';
  machineryStructuredData.textContent = JSON.stringify({
    "@context": "https://schema.org",
    "@type": "WebPage",
    "url": "https://nmt.eshikatech.com/machinery",
    "name": "Advanced Machinery & Technology",
    "description": "Discover the cutting-edge machinery and technology at NMT Packaging Pvt Ltd. Our advanced packaging equipment ensures superior quality and efficiency.",
    "publisher": {
      "@type": "Organization",
      "name": "NMT Packaging Pvt Ltd",
      "logo": {
        "@type": "ImageObject",
        "url": "https://nmt.eshikatech.com/nmt4.png"
      }
    }
  });
  document.head.appendChild(machineryStructuredData);

  return () => {
    document.head.removeChild(machineryMetaDescription);
    document.head.removeChild(machineryMetaKeywords);
    document.head.removeChild(machineryCanonicalLink);
    document.head.removeChild(machineryOgTitle);
    document.head.removeChild(machineryOgDescription);
    document.head.removeChild(machineryOgUrl);
    document.head.removeChild(machineryOgSiteName);
    document.head.removeChild(machineryOgImage);
    document.head.removeChild(machineryOgImageSecure);
    document.head.removeChild(machineryOgImageWidth);
    document.head.removeChild(machineryOgImageHeight);
    document.head.removeChild(machineryStructuredData);
  };

}, []);

  return (
    <div>
      <Banner/>
      {/* <Servicess /> */}
      <Servicess1 />
      </div>
  );
}

export default Services;
