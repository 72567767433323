import React from "react"
import classes from "./Award.module.css"
import a1 from "./../../Assest/vc.jpg";
import a2 from "./../../Assest/g2.avif";
import Fade from 'react-reveal/Fade';

const Rec = () => {
    return (
        
    <>


<div className={classes.a1}>

 <Fade left>
<div  className={classes.a12}>
        <img src={a1} className={classes.img} />
</div>
</Fade>


<div className={classes.a11}>
<Fade down>
<h3 className={classes.ih4}>Supplier Performance Of The Year  ( 2011 )</h3>
<h4 className={classes.ih44}>Awarded By M/s Hindustan Coca Cola Beverages Pvt. Ltd</h4>
</Fade>
</div>



 <Fade Right>
<div  className={classes.a12}>
        <img src={a1} className={classes.img} />
</div>
</Fade>


</div>



        </>
    )
}

export default Rec;