import React from 'react';
import classes from './H1.module.css'; // Import your CSS file

const HeroSection = () => {


    return (
         <div className={classes.container}>
        <div className={classes.overlay}></div>
        <div className={classes.content}><br/><br/><br/>
            <h1 className={classes.title}>Welcome To</h1>
              <h1 className={classes.title1}>NMT Packaging Pvt Ltd</h1>
            <p className={classes.paragraph}>
               At NMT Packaging Pvt Ltd, we understand that precision and reliability are essential in the packaging industry. Our custom corrugated packaging solutions are designed to meet your specific needs, ensuring your products are delivered safely and efficiently.

We pride ourselves on our commitment to excellence, providing top-notch customer service and innovative packaging options that adhere to the highest industry standards. With a focus on timely delivery and strict adherence to specifications, NMT Packaging is your trusted partner for all your packaging requirements. Let us help you protect your products and enhance your brand with our superior corrugated packaging solutions.


            </p><br/>
            <button className={classes.startProjectButton}>Learn More</button>
        </div>
    

        <br/>
<div style={{ position: 'relative', height: '320px', width:'100vw' }} className={classes.nnnn}> {/* Set height for context */}
    <svg 
        className={classes.wave} 
        xmlns="http://www.w3.org/2000/svg" 
        viewBox="0 0 1440 320" 
        width="100vw" 
        height="" 
        style={{ position: 'absolute', top: 0, left: 0, zIndex: 1 , width:'100vw'}}
    >
        <path 
            fill="#3c2c9a" 
            fillOpacity="1" 
            d="M0,128 C120,224 240,32 360,128 C480,224 600,32 720,128 C840,224 960,32 1080,128 C1200,224 1320,32 1440,128 L1440 320 L0 320 Z"
        ></path>
    </svg>
</div>
    </div>
    );
};

export default HeroSection;
