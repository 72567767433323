import React from 'react';
import styles from './p2.module.css';
import P from "./../../Assest/p2.jpg"
import Fade from 'react-reveal/Fade';

const MonoCartons = () => {
  return (
    <div className={styles.bgPurple}>
      <div className={`${styles.container} ${styles.flex}`}><Fade left>
        
        <div className={styles.textContainer}>
           
          <h1 className={styles.heading}>Laminated boxes</h1>
          <p className={styles.paragraph}>
          Discover NMT Packaging Pvt Ltd's laminated boxes, designed for both durability and aesthetic appeal. The high-quality lamination provides added protection against moisture and wear, ensuring your products stay pristine. With a range of customizable designs, these boxes enhance your brand's visibility. Ideal for retail and shipping, they combine functionality with style. Choose NMT Packaging for laminated boxes that elevate your packaging solutions.
   </p>
          <div className={styles.buttonContainer}>
          <button className={styles.startProjectButton}>Learn More</button>
     
          </div>
        </div></Fade>
 <Fade right>
        <div className={styles.imageContainer}>
          <img
            alt="Two perfume boxes, one purple labeled 'MIDNIGHT' and one green labeled 'COLOGNE'"
            className={styles.rotatedImage}
            height="300"
            // src="https://storage.googleapis.com/a1aa/image/GVvsK9nOGaL1MNOyOXQLe4IsQTvcrwuqv2IWRQhs1fmpGxoTA.jpg"
            src={P}
            width="300"
          />
        </div></Fade>
      </div>
    </div>
  );
};

export default MonoCartons;
